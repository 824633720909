import Service from "@/helpers/service";

export default {
  getTransactionHistory(page) {
    return Service().get("TransactionHistory?page=" + page);
  },
  pivot(page){
    return Service().get("TransactionHistory/pivot?page=" + page);
  },
  generate(){
    return Service().post("TransactionHistory/generate");
  }
};
